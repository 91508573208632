import React, { useEffect } from "react";
import Image from "next/image";
import classNames from "classnames";
import CancelMaterialIcon from "@src/components/Svg/CancelMaterialIcon";
import { IconWithLabel } from "@src/components/UI/Inputs/IconWithLabel";
import { Option, OptionsGroup } from "@src/components/UI/Inputs/SelectInput/types";
import { CloseIcon } from "@shared/ui/Icon/ui/Common/CloseIcon";
import { EMPTY_OPTIONS } from "./constants";
import { MobileSelectInputProps } from "./types";
import classes from "./MobileSelectInput.module.scss";

const DEFAULT_PLACEHOLDER = "/img/generation-placeholder-low-h.svg";

const MobileSelectInput = ({
    onClose,
    options,
    onChange,
    value,
    title,
    isImageSelect,
    isMulti = false
}: MobileSelectInputProps) => {
    const [search, setSearch] = React.useState("");
    const [isFocused, setIsFocused] = React.useState(false);
    const filterBySearch = (el: Option) => el.label.toLowerCase().includes(search.toLowerCase());
    const optionsGroup = options as OptionsGroup[];
    const isOnlyOneGroup = options.length === 1 && !Array.isArray(optionsGroup[0]?.options);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleOptionClick = (option: Option) => {
        const isSelected = isMulti && (value as Option[]).some((v) => v.value === option.value);
        const newValue = isMulti
            ? isSelected
                ? (value as Option[]).filter((v) => v.value !== option.value)
                : [...(value as Option[]), option]
            : option;
        onChange?.(newValue);
        onClose();
    };

    const handleCancelSearch = () => {
        setSearch("");
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 200);
    };

    const isOptionSelected = (option: Option) => {
        return isMulti
            ? (value as Option[]).some((v) => v.value === option.value)
            : (value as Option)?.value === option.value;
    };

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.removeProperty("overflow");
        };
    }, []);

    return (
        <div
            className={classNames(classes.MobileSelectInput, {
                [classes.ImageSelect]: isImageSelect
            })}
        >
            <div className={classes.Header}>
                <CloseIcon className={classes.CloseBtn} onClick={onClose} />
                <h3>{title}</h3>
            </div>
            <div className={classes.Search}>
                <input
                    value={search}
                    onChange={handleSearchChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {isFocused && <CancelMaterialIcon onClick={handleCancelSearch} />}
            </div>
            <div className={classes.Content}>
                <ul className={classes.OptionsList}>
                    {options.map((groupOrOption, groupIndex) => {
                        const groupOptions = (groupOrOption as OptionsGroup)?.options?.filter(
                            filterBySearch
                        );

                        if (!groupOptions)
                            return (
                                <li
                                    key={`option-${groupIndex}`}
                                    className={classNames(classes.Option, {
                                        [classes.Selected]: isOptionSelected(
                                            groupOrOption as Option
                                        )
                                    })}
                                    onClick={() => handleOptionClick(groupOrOption as Option)}
                                >
                                    {isImageSelect && (
                                        <div className={classes.ImageSelector}>
                                            <div className={classes.ImageCont}>
                                                <Image
                                                    src={
                                                        (groupOrOption as Option).pic ||
                                                        DEFAULT_PLACEHOLDER
                                                    }
                                                    alt={"option.label"}
                                                    unoptimized={true}
                                                    width={500}
                                                    height={500}
                                                    onError={(e) => {
                                                        e.currentTarget.src = DEFAULT_PLACEHOLDER;
                                                        e.currentTarget.srcset =
                                                            DEFAULT_PLACEHOLDER;
                                                    }}
                                                />
                                            </div>
                                            <div className={classes.ImageSelectorLabel}>
                                                {groupOrOption.label}
                                            </div>
                                        </div>
                                    )}
                                    {!isImageSelect && <>{groupOrOption.label}</>}
                                </li>
                            );

                        return groupOptions?.length ? (
                            <li key={`group-${groupOrOption.label}`} className={classes.GroupName}>
                                {!isOnlyOneGroup && <label>{groupOrOption.label}</label>}
                                <ul>
                                    {groupOptions.map((option: Option) => (
                                        <li
                                            key={`option-${option.label}`}
                                            className={classNames(classes.Option, {
                                                [classes.Selected]: isOptionSelected(option)
                                            })}
                                            onClick={() => handleOptionClick(option)}
                                        >
                                            {isImageSelect && (
                                                <div className={classes.ImageSelector}>
                                                    <div className={classes.ImageCont}>
                                                        <Image
                                                            src={option.pic || DEFAULT_PLACEHOLDER}
                                                            alt={"option.label"}
                                                            unoptimized={true}
                                                            width={500}
                                                            height={500}
                                                            onError={(e) => {
                                                                e.currentTarget.src =
                                                                    "/img/generation-placeholder-low-h.svg";
                                                                e.currentTarget.srcset =
                                                                    "/img/generation-placeholder-low-h.svg";
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={classes.ImageSelectorLabel}>
                                                        {option.label}
                                                    </div>
                                                </div>
                                            )}
                                            {!isImageSelect && (
                                                <>
                                                    {title === "Марка авто" ? (
                                                        <IconWithLabel option={option} />
                                                    ) : (
                                                        option.label
                                                    )}
                                                </>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ) : groupOptions?.length === 0 ? (
                            <div key={`no-results-${groupIndex}`} className={classes.NoResults}>
                                {EMPTY_OPTIONS}
                            </div>
                        ) : null;
                    })}
                </ul>
            </div>
        </div>
    );
};
export default MobileSelectInput;