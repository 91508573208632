import React, { useEffect, useState } from "react";
import Image from "next/image";
import classNames from "classnames";
import { DEFAULT_FOLDER, DEFAULT_PLACEHOLDER, DEFAULT_SIZE, GENERATION_FOLDER } from "./constants";
import { IconWithLabelProps } from "./types";
import classes from "./IconWithLabel.module.scss";

export const IconWithLabel = ({
    option,
    folder = DEFAULT_FOLDER,
    placeholderUri = DEFAULT_PLACEHOLDER
}: IconWithLabelProps) => {
    const [hasError, setHasError] = useState(false);
    const placeholder =
        folder === GENERATION_FOLDER ? "/img/generation-placeholder.svg" : placeholderUri;

    const imageSrc = `${process.env.NEXT_PUBLIC_YANDEX_STORAGE_URL}/${folder}/${option.alias || option.value}.webp`;

    useEffect(() => {
        setHasError(false);
    }, [folder, option]);

    return (
        <div
            className={classNames(classes.IconLabel, {
                [classes.IconLabel__generation]: folder === GENERATION_FOLDER
            })}
        >
            <Image
                src={hasError ? placeholder : imageSrc}
                alt={option.label}
                unoptimized={true}
                width={DEFAULT_SIZE}
                height={DEFAULT_SIZE}
                onError={() => setHasError(true)}
            />
            <span className={classes.IconLabel__name}>{option.label}</span>
        </div>
    );
};